<template>
    <div class="company">
        <teleport v-if="mountedComponent" to="#companies-header">
            <div class="company__header">
                <TopNavigation :links="topNavLinks"/>
            </div>
        </teleport>

        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem>
                <router-link :to="{ name: 'CompaniesList' }">Companies</router-link>
            </CBreadcrumbItem>

            <CBreadcrumbItem>
                <CSelectSearch v-model="selectedCompany" class="company__select" :options="companiesList" :search="true"
                               :loading="loadingCompanies"/>
            </CBreadcrumbItem>
        </teleport>

        <router-view @onSelect="$emit('onSelect', $event)"/>
    </div>
</template>

<script>
import TopNavigation from '@/components/Page/TopNavigation'
import breadcrumbsMixin from '../breadcrumbsMixin'
import Gate from '@/services/Gate/Gate'
import {computed} from 'vue'

export default {
    name: 'Index',
    components: {TopNavigation},
    mixins: [breadcrumbsMixin],
    provide() {
        return {
            mountedComponent: computed(() => this.mountedComponent),
        }
    },
    data() {
        return {
            hideNotesTab: false
        }
    },
    computed: {
        topNavLinks() {
            return [
                {
                    title: 'General Info',
                    route: {name: 'CompanyInfo', params: {id: this.$route.params.id}},
                },
                {
                    title: 'Tasks',
                    route: {
                        name: 'CompanyTasks',
                        params: {id: this.$route.params.id},
                    },
                },
                {
                    title: 'Projects',
                    route: {
                        name: 'CompanyProjects',
                        params: {id: this.$route.params.id},
                    },
                },
                {
                    title: 'Users',
                    children: [
                        'CompanyUsers',
                        'CompanyUsersList',
                        'CompanyUser',
                        'CompanyUserDetails',
                        'CompanyUserTasks',
                        'CompanyUserProjects',
                    ],
                    route: {
                        name: 'CompanyUsers',
                        params: {id: this.$route.params.id},
                    },
                },
                {
                    title: 'Documents',
                    route: {
                        name: 'CompanyDocuments',
                        params: {id: this.$route.params.id},
                    },
                },
                {
                    title: 'Notes',
                    hidden: this.hideNotesTab,
                    route: {
                        name: 'CompanyNotes',
                        params: {id: this.$route.params.id},
                    },
                },
            ]
        },
    },
    mounted() {
        this.$nextTick(async () => {
            this.hideNotesTab = await Gate.can(
                'list',
                'note',
                this.$route.params.id,
            ).then((res) => !res)
        })
    },
}
</script>

<style lang="scss" scoped>
.company {
    &__select {
        width: 162px;
    }
}
</style>
